// Edit article.template.js (article.js is generated).

import React from 'react';
import { BreakpointProvider } from '../providers/breakpoint-provider';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import WidgetList from '../components/widget-list';

const ArticleTemplate = ({ data, pageContext, location }) => {
  const page = data.contentfulArticle;
  const globalSettings = data.contentfulGlobalSettings;
  const {
    prev,
    next,
    recent,
    relatedArticles,
    categoryLandingPage,
  } = pageContext;
  
  const extra = {
    forms: globalSettings,
    // Added articleData to extra, to be able to pass it to the widget
    articleData: page,
    prev,
    next,
    recent,
    relatedArticles,
    categoryLandingPage,
  };
  return (
    <BreakpointProvider>
      <Layout location={location} globalSettings={globalSettings} seoData={page}>
        <WidgetList location={location} widgets={page.mainContent} extra={extra}></WidgetList>
      </Layout>
    </BreakpointProvider>
  );
};

export const Head = ({data}) => {
  const page = data.contentfulArticle;
  const {
    pageName,
    seoPageTitle,
    metaDescription,
    openGraphTitle,
    openGraphImage,
    openGraphDescription,
  } = page;

  const defaultTitle = seoPageTitle || pageName;
  const ogTitle = openGraphTitle || pageName;
  const ogDescription = openGraphDescription || metaDescription;
  const image = openGraphImage
    ? openGraphImage.image.resize.src
    : (page.image?.image?.resize ? page.image.image.resize.src : '');
  return <>
    <title>{defaultTitle}</title>
    <meta name="description" content={metaDescription} />
    <meta name="og:description" content={ogDescription} />
    <meta name="image" content={image} />
    <meta name="og:title" content={ogTitle} />
    <meta name="og:type" content="website" />
    <meta name="og:image" content={image} />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content={defaultTitle} />
    <meta name="twitter:description" content={metaDescription} />
  </>;
};

// This query is injected. To edit it, go to generate-article.js.
  export const pageQuery = graphql`
  query ArticleBySlug(
    $slug: String!
  ) {
    contentfulArticle(slug: { eq: $slug }) {
      slug
      pageName
      categoryType
      categories
      publishDate
      type
      seoPageTitle
      metaDescription
      schemaJson {
        schemaJson
      }
      openGraphTitle
      openGraphDescription
      openGraphImage {
        image {
          gatsbyImage(layout: FULL_WIDTH, placeholder: BLURRED, width: 616),
          resize(height: 616, width: 616) {
            src
          }
          src: url
        }
      }
      headerRichText: header {
        raw
      }
      eyebrow
      author {
        ...BioFragment
      }
      contributors {
        ...BioFragment
      }
      image {
        image {
          gatsbyImage(layout: FULL_WIDTH, placeholder: BLURRED, width: 2030),
          resize(height: 1332, width: 2030) {
            src
          }
          src: url
        }
      }
      mainContent {
        ...ArticleCarouselQuery
        ...ArticleDetailsQuery
        ...CerosExperienceArticleDetailsQuery
        ...LinksCalloutQuery
        ...NewsletterCalloutQuery
        ...RelatedNewsQuery
      }
      headerWhiteBackground
    }
    contentfulGlobalSettings {
      ...GlobalSettingsFragment
    }
  }
  
  fragment BioFragment on ContentfulBio {
    name
    designation
    category
    socialNetworkLinks {
      ...UrlLinkFragment
    }
    shortBio {
      raw
    }
    photo {
      image {
        gatsbyImage(layout: FULL_WIDTH, placeholder: BLURRED, width: 616),
        resize(height: 616, width: 616) {
          src
        }
        src: url
      }
    }
  }
`

export default ArticleTemplate;